import $ from 'jquery';
window.$ = $;
window.jQuery = $;
import './libs/bootstrap-notify';
import {Modal} from 'bootstrap'

// function initializeDefaultMap(Lat, Long, eleMap, markerImg) {
//     let icon = {
//         url: markerImg,
//         scaledSize: new google.maps.Size(50, 50),
//     }
//     let myLatlng = new google.maps.LatLng(Lat, Long);
//     let myOptions = {
//         zoom: 15,
//         backgroundColor: 'none',
//         center: myLatlng,
//         mapTypeControlOptions: {
//             mapTypeIds: [/*'roadmap', 'satellite', 'hybrid', 'terrain',*/
//                 'satellite']
//         }
//
//     }
//     let map = new google.maps.Map(eleMap, myOptions);
//
//     let marker = new google.maps.Marker({
//         position: myLatlng,
//         icon: icon,
//     });
//     marker.setMap(map);
// }

function initializeMap(Lat, Long, eleMap, markerImg) {
    let styledMapType = new google.maps.StyledMapType(
    [
        // {
        //     featureType: 'poi',
        //         elementType: 'business',
        //     stylers: [{ visibility: 'off' }] // Hide all businesses
        // },
        // {
        //     featureType: 'poi',
        //         elementType: 'business.restaurant',
        //     stylers: [{ visibility: 'off' }] // Hide specifically restaurants
        // },
    //         {
    //             "elementType": "geometry",
    //             "stylers": [
    //                 {
    //                     "color": "#f5f5f5"
    //                 }
    //             ]
    //         },
    //         {
    //             "elementType": "geometry.fill",
    //             "stylers": [
    //                 {
    //                     "color": "#e5ebf4"
    //                 }
    //             ]
    //         },
    //         {
    //             "elementType": "labels.icon",
    //             "stylers": [
    //                 {
    //                     "visibility": "off"
    //                 }
    //             ]
    //         },
    //         {
    //             "elementType": "labels.text.fill",
    //             "stylers": [
    //                 {
    //                     "color": "#616161"
    //                 }
    //             ]
    //         },
    //         {
    //             "elementType": "labels.text.stroke",
    //             "stylers": [
    //                 {
    //                     "color": "#f5f5f5"
    //                 }
    //             ]
    //         },
    //         {
    //             "featureType": "administrative.land_parcel",
    //             "elementType": "labels.text.fill",
    //             "stylers": [
    //                 {
    //                     "color": "#bdbdbd"
    //                 }
    //             ]
    //         },
    //         {
    //             "featureType": "landscape.man_made",
    //             "elementType": "geometry.fill",
    //             "stylers": [
    //                 {
    //                     "color": "#e5ebf4"
    //                 }
    //             ]
    //         },
    //         {
    //             "featureType": "poi",
    //             "elementType": "geometry",
    //             "stylers": [
    //                 {
    //                     "color": "#eeeeee"
    //                 }
    //             ]
    //         },
    //         {
    //             "featureType": "poi",
    //             "elementType": "geometry.fill",
    //             "stylers": [
    //                 {
    //                     "color": "#e5ebf4"
    //                 }
    //             ]
    //         },
    //         {
    //             "featureType": "poi",
    //             "elementType": "labels.text.fill",
    //             "stylers": [
    //                 {
    //                     "color": "#757575"
    //                 }
    //             ]
    //         },
    //         {
    //             "featureType": "poi.park",
    //             "elementType": "geometry",
    //             "stylers": [
    //                 {
    //                     "color": "#e5e5e5"
    //                 }
    //             ]
    //         },
    //         {
    //             "featureType": "poi.park",
    //             "elementType": "geometry.fill",
    //             "stylers": [
    //                 {
    //                     "color": "#e5ebf4"
    //                 }
    //             ]
    //         },
    //         {
    //             "featureType": "poi.park",
    //             "elementType": "labels.text.fill",
    //             "stylers": [
    //                 {
    //                     "color": "#9e9e9e"
    //                 }
    //             ]
    //         },
    //         {
    //             "featureType": "road",
    //             "elementType": "geometry",
    //             "stylers": [
    //                 {
    //                     "color": "#ffffff"
    //                 }
    //             ]
    //         },
    //         {
    //             "featureType": "road",
    //             "elementType": "geometry.fill",
    //             "stylers": [
    //                 {
    //                     "color": "#b2c3da"
    //                 }
    //             ]
    //         },
    //         {
    //             "featureType": "road.arterial",
    //             "elementType": "labels.text.fill",
    //             "stylers": [
    //                 {
    //                     "color": "#757575"
    //                 }
    //             ]
    //         },
    //         {
    //             "featureType": "road.highway",
    //             "elementType": "geometry",
    //             "stylers": [
    //                 {
    //                     "color": "#dadada"
    //                 }
    //             ]
    //         },
    //         {
    //             "featureType": "road.highway",
    //             "elementType": "geometry.fill",
    //             "stylers": [
    //                 {
    //                     "color": "#b2c3da"
    //                 }
    //             ]
    //         },
    //         {
    //             "featureType": "road.highway",
    //             "elementType": "labels.text.fill",
    //             "stylers": [
    //                 {
    //                     "color": "#616161"
    //                 }
    //             ]
    //         },
    //         {
    //             "featureType": "road.local",
    //             "elementType": "labels.text.fill",
    //             "stylers": [
    //                 {
    //                     "color": "#9e9e9e"
    //                 }
    //             ]
    //         },
    //         {
    //             "featureType": "transit.line",
    //             "elementType": "geometry",
    //             "stylers": [
    //                 {
    //                     "color": "#e5e5e5"
    //                 }
    //             ]
    //         },
    //         {
    //             "featureType": "transit.station",
    //             "elementType": "geometry",
    //             "stylers": [
    //                 {
    //                     "color": "#eeeeee"
    //                 }
    //             ]
    //         },
    //         {
    //             "featureType": "transit.station",
    //             "elementType": "geometry.fill",
    //             "stylers": [
    //                 {
    //                     "color": "#c0c8d5"
    //                 }
    //             ]
    //         },
    //         {
    //             "featureType": "water",
    //             "elementType": "geometry",
    //             "stylers": [
    //                 {
    //                     "color": "#c9c9c9"
    //                 }
    //             ]
    //         },
    //         {
    //             "featureType": "water",
    //             "elementType": "geometry.fill",
    //             "stylers": [
    //                 {
    //                     "color": "#c4d3e9"
    //                 }
    //             ]
    //         },
    //         {
    //             "featureType": "water",
    //             "elementType": "labels.text.fill",
    //             "stylers": [
    //                 {
    //                     "color": "#9e9e9e"
    //                 }
    //             ]
    //         }
        ],
        {name: 'Map'});

    let icon = {
        url: markerImg,
        scaledSize: new google.maps.Size(50, 50),
    }
    let myLatlng = new google.maps.LatLng(Lat, Long);
    let myOptions = {
        zoom: 15,
        backgroundColor: 'none',
        center: myLatlng,
        mapTypeControlOptions: {
            mapTypeIds: [/*'roadmap', 'satellite', 'hybrid', 'terrain',*/
                'satellite','styled_map']
        }

    }
    let map = new google.maps.Map(eleMap, myOptions);
    map.mapTypes.set('styled_map', styledMapType);
    map.setMapTypeId('styled_map');

    let marker = new google.maps.Marker({
        position: myLatlng,
        icon: icon,
    });
    marker.setMap(map);
}

function startBtnLoader(btn) {
    let sel = $(btn);
    if(typeof btn === 'object'){
        sel = btn;
    }
    sel.addClass('disabled');
    sel.data('html', sel.html());
    sel.html(`<div class="btn-loader-gif">
        <div class="loader-demo-box">
            <div class="jumping-dots-loader"> <span></span> <span></span> <span></span> </div>
        </div>
    </div>`);
    sel.addClass('inValid');
}

function stopBtnLoader(btn) {
    let sel = $(btn);
    if(typeof btn === 'object'){
        sel = btn;
    }
    if(!sel.hasClass('inValid')) {
        return ;
    }
    sel.removeClass('disabled');
    sel.html(sel.data('html'));
    sel.data('html', '');
    sel.removeClass('inValid');
}

function displaySuccess(successResponse) {
    let message = successResponse.response.message;

    let messageArr = [];
    $.each(message, function(idx2, val2) {
        messageArr.push(val2);
    });
    let messageStr = messageArr.join(", ");
    displayMessageToast(messageStr, 'success');
}

function displayMessageToast(message, type) {

    if(type === 'error') {
        type = 'danger';
    }

    let toastContainer = $('#toast-container');

    $.notify({
        // options
        // icon: 'glyphicon glyphicon-warning-sign',
        // title: 'Bootstrap notify',
        message: message,
        // url: 'https://github.com/mouse0270/bootstrap-notify',
        // target: '_blank'
    },{
        // settings
        element: 'body',
        position: null,
        type: type,
        allow_dismiss: true,
        newest_on_top: false,
        showProgressbar: false,
        placement: {
            from: "top",
            align: "center"
        },
        offset: 100,
        spacing: 10,
        z_index: 9999999,
        delay: 10000,
        timer: 1000,
        url_target: '_blank',
        mouse_over: null,
        animate: {
            enter: 'animated fadeInDown',
            exit: 'animated fadeOutUp'
        },
        onShow: null,
        onShown: null,
        onClose: null,
        onClosed: null,
        icon_type: 'class',
        template: toastContainer.html()
    });

    // toastContainer.toast({
    //     delay: 50000000
    // }).toast('show');
}

function handleAuthSuccess(href) {

    // displayMessageToast('Thank you for registering with us, our team will reach out to you shortly', 'sucesss');
    // showNotifierAlertRedirectHome(null, 'Thank you for registering with us, our team will reach out to you shortly', 'success');

    // let lastChar = href.charAt(href.length-1);
    // if(lastChar != '/' && !hasQueryParams(href)) {
    //     href += '/';
    // }
    //
    // //remove protocal
    // let withoutProtocolhref = href.replace('http://', '').replace('https://', '');
    //
    // let hrefDomain = withoutProtocolhref.split('/')[0];
    //
    // if(hrefDomain.replace(/^[^.]+\./g, "") != window.location.hostname.replace(/^[^.]+\./g, "")) {
    //     window.location.reload();
    //     return;
    // }
    //
    // if ((window.location.pathname !== '/login') && (window.location.pathname !== '/signup')) {
    //
    //     window.location.href = window.location.href.split("?")[0];
    //     return;
    // }

    window.location.href = href;
}

function showNotifierAlertRedirectHome(title, msg, status) {

    displayMessageAlertWithTimer(title, msg, status);

    setTimeout(function () {
        window.location.href = '/';
    }, 5000);
}

function showNotifierAlertRedirectSame(title, msg, status) {

    displayMessageAlertWithTimer(title, msg, status);

    setTimeout(function() {
        window.location.href = window.location.href.replace('&concurrent=1', '').replace('?concurrent=1', '');
    }, 5000);
}

function displayMessageAlertWithTimer(title, message, type) {
    let alertPopUpWrapper = $('#alert_modal');
    alertPopUpWrapper.find('.alert-box-wrapper').removeClass('alert-box-error');
    alertPopUpWrapper.find('.alert-box-wrapper').addClass('alert-box-'+type);

    if(title) {
        alertPopUpWrapper.find('.box-title-wrapper .title').text(title);
    }

    let subtitle = alertPopUpWrapper.find('.box-subtitle-wrapper .subtitle');


    // console.log(alertPopUpWrapper);
    new Modal($('#alert_modal')).show();
    // alertPopUpWrapper.modal({backdrop: 'static', keyboard: false}, 'show');


    // console.log('here');
    let secsTime = 5;
    let timerSubtitle = message+'<br />You will be redirected in '+secsTime+' seconds';
    subtitle.html(timerSubtitle);

    let notifierTimer = setInterval(function() {
        timerSubtitle = message+'<br />You will be redirected in '+secsTime+' seconds';
        secsTime--;
        subtitle.html(timerSubtitle);

        if(secsTime <= 0) {
            clearInterval(notifierTimer);
            secsTime = 0;
        }
    }, 1000);
}

function displayError(errorResponse) {
    var responseJSON = errorResponse.responseJSON;
    var responseCode = responseJSON.responseCode;
    var message = responseJSON.response.message;
    if(responseCode === 'VALIDATION_EXCEPTION') {
        // displayValidationError(message);
        return ;
    }

    if(responseCode === 'MAXIMUM_ATTEMPTS_EXCEEDED') {
        // window.location.href = '/';
    }

    var messageArr = [];
    $.each(message, function(idx2, val2) {
        messageArr.push(val2);
    });
    var messageStr = messageArr.join(", ");
    displayMessageToast(messageStr, 'error');
}

function displayErrorDirect(errorResponse) {
    if(!errorResponse.response) {
        displayMessageToast('Unable to upload', 'error');
        return ;
    }
    var message = errorResponse.response.message;
    var messageArr = [];
    $.each(message, function(idx2, val2) {
        messageArr.push(val2);
    });
    var messageStr = messageArr.join(", ");
    displayMessageToast(messageStr, 'error');

}

function gaSubmitEvent (type) {
    try {
        ga('send', 'event', type, 'Submit');
        gtag('event', type);
    }
    catch (err) {

    }
}

function fbSubmitEvent (type) {
    try {
        fbq('track', type);
    }
    catch (err) {

    }
}

function getUrlParam(param) {
    // Get the URL parameters
    var urlParams = new URLSearchParams(window.location.search);

    // Example: Get the value of 'param1'
    return urlParams.get(param);
}

function updateQueryParam(oldUrl, param, value) {
    // Create a URL object
    let url = new URL(oldUrl);

    // Remove the 'section' parameter from the URL
    url.searchParams.delete('section');

    // Add a new query parameter (e.g., 'newParam' with value 'newValue')
    url.searchParams.set(param, value);

    // Get the updated URL
    var updatedURL = url.toString();

    return updatedURL;
}

function scrollTo(id) {
    var targetPosition = $(id).offset().top;

    // Scroll to the element with animation
    $('html, body').animate({
        scrollTop: targetPosition
    }, 1000); // Adjust the duration as needed
}

export default {
    // initializeDefaultMap,
    initializeMap,
    startBtnLoader,
    stopBtnLoader,
    displaySuccess,
    displayMessageToast,
    handleAuthSuccess,
    showNotifierAlertRedirectSame,
    showNotifierAlertRedirectHome,
    displayMessageAlertWithTimer,
    displayError,
    displayErrorDirect,
    gaSubmitEvent,
    fbSubmitEvent,
    getUrlParam,
    updateQueryParam,
    scrollTo,
};
